<template>
  <div>
    <v-menu offset-y v-model="menu.open">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          label="Search Customer"
          type="text"
          v-model="customerSearchTerm"
          outlined
          dense
          hide-details
          :loading="widgetLoading"
          @blur="handleUpdateItem()"
          @click="activateInput"
          @keyup.enter.native="handleUpdateItem()"
        ></v-text-field>
      </template>

      <v-list>
        <v-list-item
          @click="handleClick(item)"
          v-for="(item, index) in customers"
          :key="index"
          :value="index"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="customerDialog.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="addCustomer" method="post">
          <v-card-title>
            <span class="headline">{{ customerDialog.customer.name }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            {{ customerDialog.customer.shortcode }}<br />{{
              customerDialog.customer.phone_number
            }}<br />
            {{ customerDialog.customer.website }}</v-card-text
          >
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customerSearchTerm: "",
      active: true,
      customerDialog: {
        dialog: false,
        loading: false,
        customer: {},
        errors: {},
      },

      menu: {
        open: false,
        errors: {},
      },

      searchTerm: "",
      widgetLoading: true,
    };
  },

  mounted() {
    this.loadCustomers();
  },

  computed: {
    customers() {
      let customers = this.$store.state.customers["customers"];
      if (this.customerSearchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.customerSearchTerm.toLowerCase()) ||
            shortcode.includes(this.customerSearchTerm.toLowerCase())
          );
        });
      }

      return customers;
    },
  },

  watch: {},

  methods: {
    handleUpdateItem() {
      this.active = false;
      this.menu.open = true;
    },

    activateInput() {
      this.active = true;
    },

    handleClick(customer) {
      this.customerDialog.dialog = true;
      this.customerDialog.customer = customer;
    },

    loadCustomers: function () {
      const appId = this.$route.params.id;

      Promise.all([
        this.$store.dispatch("customers/loadCustomers", { appId }),
      ]).then(() => {
        this.widgetLoading = false;
      });
    },
  },
};
</script>
